import React from "react"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import "dayjs/locale/ar"

// Initialize dayjs plugins
dayjs.extend(relativeTime)
dayjs.locale("ar")

export const ExpirationStatus = ({
  expirationDate,
}: {
  expirationDate: string
}) => {
  const now = dayjs()
  const expiration = dayjs(expirationDate)
  const daysUntilExpiration = expiration.diff(now, "day")

  const getStatusConfig = () => {
    if (expiration < now) {
      return {
        color: "bg-red-100 text-red-800",
        icon: "🚨",
        message: "انتهت صلاحية رمز الوصول للصفحة",
        description: "يرجى تجديد رمز الوصول للفيسبوك لاستعادة نشر المحتوى",
      }
    }

    if (daysUntilExpiration <= 2) {
      return {
        color: "bg-yellow-100 text-yellow-800",
        icon: "⚠️",
        message: `ستنتهي الصلاحية ${expiration.fromNow()}`,
        description: "قم بتجديد رمز الوصول قريبًا لتجنب انقطاع النشر",
      }
    }

    if (daysUntilExpiration <= 30) {
      return {
        color: "bg-blue-100 text-blue-800",
        icon: "🕒",
        message: `متبقي ${daysUntilExpiration} يومًا على انتهاء الصلاحية`,
        description: "يُنصح بتجديد رمز الوصول في أقرب وقت",
      }
    }

    return {
      color: "bg-green-100 text-green-800",
      icon: "✅",
      message: `صالح حتى ${expiration.format("DD-MM-YYYY")}`,
      description: "رمز الوصول للصفحة سليم وجاهز للاستخدام",
    }
  }

  const { color, icon, message, description } = getStatusConfig()

  return (
    <div
      className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${color}`}
    >
      <span className="text-2xl">{icon}</span>
      <div className="flex flex-col">
        <span className="text-sm font-medium">{message}</span>
        <span className="text-xs opacity-75">{description}</span>
      </div>
    </div>
  )
}
