import { useQuery } from "@tanstack/react-query"
import { FaBook, FaChalkboardTeacher, FaMosque } from "react-icons/fa"
import { getCountDashboard } from "../../services/definitions"
import Loading from "../../components/shared/Loading"
import { getAllLives } from "../../services/lives"
import { ExpirationStatus } from "./ExpirationStatus"

const HomePage = () => {
  const { data: dashboardData, isPending: isLoading } = useQuery({
    queryKey: ["categories"],
    queryFn: () => getCountDashboard(),
  })

  const { data: livesData } = useQuery({
    queryKey: ["lives"],
    queryFn: () => getAllLives(0, 2, ""),
  })

  if (isLoading) return <Loading />

  return (
    <div className="max-w-5xl mx-auto">
      {livesData?.content?.[0] && (
        <div className="mb-6">
          <ExpirationStatus expirationDate={livesData.content[0].expiredDate} />
        </div>
      )}
      <div className="flex justify-between items-center rounded-xl bg-white shadow-xl py-7 px-10">
        {/* Rest of your existing component code */}
        <div className="flex items-center">
          <div className="flex flex-col">
            <p className="text-lg text-gray-600">عدد التعريفات</p>
            <p className="text-4xl font-bold">
              {dashboardData?.definitions_count}
            </p>
          </div>
          <div className="bg-green-800 bg-opacity-75 rounded-full p-5 mr-2">
            <FaBook className="h-12 w-auto text-green-300 " />
          </div>
        </div>
        <div className="h-[4rem] w-[0.2rem] bg-neutral-300 "></div>
        <div className="flex items-center">
          <div className="flex flex-col">
            <p className="text-lg text-gray-600">عدد دروس تربوية</p>
            <p className="text-4xl font-bold">{dashboardData?.lessons_count}</p>
          </div>
          <div className="bg-green-800 bg-opacity-75 rounded-full p-5 mr-2">
            <FaChalkboardTeacher className="h-12 w-auto text-green-300 font-extrabold" />
          </div>
        </div>
        <div className="h-[4rem] w-[0.2rem] bg-neutral-300 "></div>
        <div className="flex items-center">
          <div className="flex flex-col">
            <p className="text-lg text-gray-600">عدد خطب الجمعة</p>
            <p className="text-4xl font-bold">
              {dashboardData?.sermonFriday_count}
            </p>
          </div>
          <div className="bg-green-800 bg-opacity-75 rounded-full p-5 mr-2">
            <FaMosque className="h-12 w-auto text-green-300 font-extrabold" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage
